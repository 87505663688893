import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDz_PgszOFiNG0Ma1oUuOu5y3x331Nxl8o",
  authDomain: "frontends-f1a04.firebaseapp.com",
  projectId: "frontends-f1a04",
  storageBucket: "gs://frontends-f1a04.appspot.com",
  messagingSenderId: "766633257438",
  appId: "1:766633257438:web:ddd4b30b23764b8c9c954f",
  measurementId: "G-6ZE18PM3P7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Working with strorage
const storage = getStorage();
const storageRef = ref(storage);

// Authenticated with Firebase
const auth = getAuth(app);

export { auth, storageRef };
export default app;
