export const columnConfig = [
  {
    key: "id",
    label: "Позиция",
  },
  {
    key: "name",
    label: "User",
  },
];
