import "./course-about.scss";

const CourseAbout = () => {
  return (
    <section className="course-about">
      <div className="container mx-auto">
        <span>
          Курс арқылы сіз сайт жасауды үйренесіз. Яғни cол сайт құрастырушы
          басты инструментермен танысасыз. HTML5, CSS негіздері және де
          қәзіргі таңдағы ең танымал инструмент REACT-пен танысып қана қоймай көп
          практика аласыз, оған қоса бонус сабақтарға тегін доступ. Резюмені қалай
          дұрыс толтыру, Собеседованияда қойылатын 20 сұраққа жауап, жұмыс
          іздегенде неге қарау керек сиақты қосымша сабақтар болады.
        </span>
      </div>
    </section>
  );
};

export default CourseAbout;
