import { TableColumns } from "helpers/tableColumns";

export const formFields = [
  TableColumns.name,
  {
    key: "courseId",
    label: "course ID",
    inputType: "hidden",
  },
];
