// import { ThemeProvider } from "styled-components";
import Header from "./components/Header/Header";
import "./scss/index.scss";
import AppRouter from "router";
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  PaletteMode,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthUser } from "redux/selectors/getUser";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "firebaseConfig";
import themeState from "states/themeEventTarget";
import { useTheme } from "@emotion/react";

// const theme = {
//   colors: {
//     red: "#a60a3d",
//     black: "#1d1d1d",
//     yellow: "#ffd873",
//     lightYellow: "#ffecbf",
//     lightBlue: "#f4fbff",
//     blue: "#3d3bff",    
//   },
// };

export const startDay = "15 Тамыз";

const App = () => {
  const [mode, setMode] = useState<any>(themeState.theme)

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        mode: mode
      }
    })
  }, [mode])

  console.log("theme: ", theme.palette.mode);


  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("firebase user", user);

        if (user) {
          const idToken = await user.getIdToken();
        }
      } else {
        // User is signed out
        console.log("user is logged out");
      }
    });
  }, []);

  useEffect(() => {
    if (themeState.theme) {
      document.querySelector('html')?.setAttribute('data-theme', themeState.theme)
      setMode(themeState.theme)
    }

    themeState.addEventListener('theme', (e) => {
      if (e instanceof CustomEvent) {
        console.log("storage:", e.detail);
        document.querySelector('html')?.setAttribute('data-theme', e.detail)
        setMode(e.detail)
      }
    })
  }, [])



  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
};

export default App;
